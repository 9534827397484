import axios from "axios";

export const Authurize = (e, b) => {
    const LogU = (a, c) => {

        const config = {
            method: 'post',
            url: 'https://hsap.ir/api/user/generate_auth_cookie/?username=' + a + '%20&password=' + c + '',
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                if (response.data.status === 'ok') {
                    let message = `${response.data.user.nicename} عزیز، خوش آمدید.`;

                    document.cookie = "cookie="+ response.data.cookie +";path=/";
                    document.cookie = "cookie_admin="+ response.data.cookie_admin +";path=/";
                    document.cookie = "cookie_name="+ response.data.cookie_name +";path=/";
                    alert(message);
                    window.location.href = "/"
                }
                else {
                    alert(response.data.error)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    // console.log(e, b)
    LogU(e, b);
};