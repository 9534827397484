import React from 'react';
import Header from "../Components/Header";
import {Container} from "react-bootstrap";

const Home = () => {
    return (
        <>
            <Header/>
            <Container>

            <h1 className={"text-center"}>
                خوش آمدید
            </h1>
            </Container>
        </>
    );
};

export default Home;