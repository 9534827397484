import {Routes, Route} from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import {useCookies} from "react-cookie";
import AddProduct from "../Pages/AddProduct";


const Routting = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['cookie_admin']);
    if (cookies["cookie_admin"]) {
        return (
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/addproduct" exact={true} element={<AddProduct/>}/>
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route path="*" element={<Login/>}/>
            </Routes>
        )
    }
};

export default Routting;