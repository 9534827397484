import React from 'react';
import {Authurize} from "../Components/Authurize";
import {Button, Col, Container, Form, Row} from "react-bootstrap";

function rtg(e) {
    e.preventDefault();
    let b = document.getElementById("username").value;
    let u = document.getElementById("password").value;
    Authurize(b, u)
};

const Login = () => {

    return (
        <Container>
            <Row>
                <Col lg={4} xs={12} className={"mx-auto"}>
                    <Form id={"loginform"} onSubmit={rtg}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>ایمیل و یا نام کاربری</Form.Label>
                            <Form.Control type="text" placeholder="ایمیل و یا نام کاربری خود را وارد کنید" id={"username"}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>رمز عبور</Form.Label>
                            <Form.Control type="password" placeholder="رمز عبور خود را وارد کنید" id={"password"}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            ورود
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;