import Routting from "./Components/Routting";

function App() {
  return (
    <div className="App">
        <Routting/>
    </div>
  );
}

export default App;
