import {Button, Form, Container, Row, Card, Col, ListGroup, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios'
import {useCookies} from "react-cookie";
import Header from "../Components/Header";

export default function AddProduct({itemid}) {
    const FormData = require('form-data');
    const [cat, setCat] = useState(null);
    const [loading, setLoading] = useState(0);
    const [imgId, setImgId] = useState(null);

    const uploadProduct = (e) => {
        e.preventDefault();
        setLoading(1);
        const __prName = document.getElementById('prName').value;
        const __prPrice = document.getElementById('prPrice').value;
        const __prStatus = (document.getElementById('prStatus').checked) ? 'publish' : 'draft';
        const __prCategory = Number(document.getElementById('prCategory').value);
        const __prdescription = document.getElementById('prdescription').value;
        const __prStock = document.getElementById('prStock').value;
        const __prSKU = document.getElementById('prSKU').value;
        const __prAttributes0 = document.getElementById('prAttributes0').value;
        const __prAttributes1 = document.getElementById('prAttributes1').value;
        const __prAttributes2 = document.getElementById('prAttributes2').value;
        const __prAttributes3 = document.getElementById('prAttributes3').value;
        const __prAttributes4 = document.getElementById('prAttributes4').value;
        const __prAttributes5 = document.getElementById('prAttributes5').value;
        const __prAttributes6 = document.getElementById('prAttributes6').value;
        const __prAttributes7 = document.getElementById('prAttributes7').value;
        const __prAttributes8 = document.getElementById('prAttributes8').value;
        const __prAttributes9 = document.getElementById('prAttributes9').value;
        const __prWeight = document.getElementById('prWeight').value;
        const __prLength = document.getElementById('prLength').value;
        const __prWidth = document.getElementById('prWidth').value;
        const __prHeight = document.getElementById('prHeight').value;
        const __prImage = document.getElementById('productImageUrl').value;
        let data = {};
        data["name"] = __prName;
        data["price"] = __prPrice;
        data["regular_price"] = __prPrice;
        data["status"] = __prStatus;
        data["description"] = __prdescription;
        data["stock_quantity"] = (__prStock == "") ? 0 : __prStock;
        data["sku"] = __prSKU;
        data["categories"] = [
            {
                "id": __prCategory
            }
        ];
        data["attributes"] = [
            {
                "id": 13,
                "name": "نویسنده",
                "position": 0,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes0
                ]
            },
            {
                "id": 2,
                "name": "مترجم",
                "position": 1,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes1
                ]
            },
            {
                "id": 11,
                "name": "ناشر",
                "position": 2,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes2
                ]
            },
            {
                "id": 3,
                "name": "سال-انتشار",
                "position": 3,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes3
                ]
            },
            {
                "id": 5,
                "name": "نوبت-چاپ",
                "position": 4,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes4
                ]
            },
            {
                "id": 8,
                "name": "موضوع",
                "position": 5,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes5
                ]
            },
            {
                "id": 6,
                "name": "تعداد-صفحات",
                "position": 6,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes6
                ]
            },
            {
                "id": 4,
                "name": "قطع",
                "position": 7,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes7
                ]
            },
            {
                "id": 14,
                "name": "ابعاد",
                "position": 8,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes8
                ]
            },
            {
                "id": 10,
                "name": "جلد",
                "position": 9,
                "visible": true,
                "variation": false,
                "options": [
                    __prAttributes9
                ]
            }
        ];
        data["weight"] = __prWeight;
        data["dimensions"] =
            {
                "length": __prLength,
                "width": __prWidth,
                "height": __prHeight
            };
        data["images"] = (__prImage.length > 0) ?
            [
                {
                    src: __prImage
                },
            ] : null
        ;
        let config = {
            method: 'post',
            data: data,
            url: 'https://hsap.ir/wp-json/wc/v3/products?consumer_key=ck_d85361d33b33b08b44ed2b931f371e2f0d79f830&consumer_secret=cs_af85ce7cc81ace823b18f1178e6b57860b09b0cf',
            headers: {}
        };
        console.log(data)

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                let elements = document.getElementsByTagName("input");
                for (let ii = 0; ii < elements.length; ii++) {
                    elements[ii].value = "";
                }
                setLoading(0);
                alert("محصول ثبت شد!");
            })
            .catch(function (error) {
                setLoading(0);
                alert(error.response.data.message);
            });
    }

    let config = {
        method: 'get',
        url: 'https://hsap.ir/wp-json/wc/v3/products/categories?per_page=100&consumer_key=ck_d85361d33b33b08b44ed2b931f371e2f0d79f830&consumer_secret=cs_af85ce7cc81ace823b18f1178e6b57860b09b0cf',
        headers: {}
    };

    const productCats = () => {
        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setCat(response.data)
            })
            .catch(function (error) {
                alert(error);
            });
    }

    useEffect(() => {
        productCats();
    }, []);

    const uploadImage = () => {
        setLoading(1)
        let data = new FormData();

        data.append("mmbyapi_file_upload[]", document.getElementById("thumnailImage").files[0], document.getElementById("thumnailImage").value.split(/(\\|\/)/g).pop());
        let imgConfig = {
            method: 'post',
            url: 'https://hsap.ir/wp-json/mmbyapi/v1/upload-multiple-images',
            headers: {},
            data: data
        };

        axios(imgConfig)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setImgId(response.data[0]);
                getImageUrl(response.data[0])
            })
            .catch(function (error) {
                alert(error);
                setLoading(0)
            });
    }

    const getImageUrl = (a) => {
        let configImageUrl = {
            method: 'get',
            url: 'https://hsap.ir/wp-json/wp/v2/media/' + a + '',
            headers: {}
        };

        axios(configImageUrl)
            .then(function (response) {
                // console.log(response.data.guid.rendered)
                document.getElementById('productImageUrl').value = response.data.guid.rendered
                setLoading(0)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(0)
            });

    }
    if (cat === null) {
        return (<Spinner animation="border" className="customeSpinner"/>)
    } else {
        return (
            <>  <Header/>
                {loading === 1 ? <Spinner animation="border" className="customeSpinner"/> : ''}
                <div className="mt-3">
                    <Container>
                        <Row>
                            <Col>
                                <Form onSubmit={uploadProduct}>
                                    <Card className="bg-dark">
                                        <Card.Title className="text-center pt-3 text-white">ثبت محصول</Card.Title>
                                        <Card.Body>
                                            <Form.Group htmlFor="formFile" className="mb-3 text-white">
                                                <Form.Label className={"text-white"}>آپلود عکس</Form.Label>
                                                <Form.Control type="file" id={"thumnailImage"} onChange={uploadImage}/>
                                            </Form.Group>
                                        </Card.Body>
                                        <Card.Body>
                                            <ListGroup.Item>
                                                <Form.Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>نام محصول</Form.Label>
                                                            <Form.Control type="text" placeholder="نام محصول"
                                                                          id="prName"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>قیمت</Form.Label>
                                                            <Form.Control type="number" placeholder="قیمت"
                                                                          id="prPrice"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>شناسه محصول</Form.Label>
                                                            <Form.Control type="text" placeholder="شناسه محصول"
                                                                          id="prSKU"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>دسته بندی</Form.Label>
                                                            <Form.Control as="select" id="prCategory">
                                                                <option value="0" data_href="null">انتخاب کنید
                                                                </option>
                                                                {
                                                                    cat.map((item, i) =>
                                                                        <option key={i} value={item.id}
                                                                                data_href={item.slug}>{item.name}</option>
                                                                    )
                                                                }
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>توضیحات</Form.Label>
                                                            <Form.Control type="text" placeholder="توضیحات"
                                                                          id="prdescription" as={"textarea"}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Form.Row>
                                                    <Col lg={12}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>موجودی انبار</Form.Label>
                                                            <Form.Control type="number" placeholder="موجودی انبار"
                                                                          id="prStock"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>وزن (kg)</Form.Label>
                                                            <Form.Control type="text" placeholder="وزن (kg)"
                                                                          id="prWeight"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>طول</Form.Label>
                                                            <Form.Control type="number" placeholder="طول"
                                                                          id="prLength"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>عرض</Form.Label>
                                                            <Form.Control type="number" placeholder="عرض"
                                                                          id="prWidth"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>ارتفاع</Form.Label>
                                                            <Form.Control type="number" placeholder="ارتفاع"
                                                                          id="prHeight"/>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Form.Row>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>نویسنده</Form.Label>
                                                            <Form.Control type="text" placeholder="نویسنده"
                                                                          id="prAttributes0"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>مترجم</Form.Label>
                                                            <Form.Control type="text" placeholder="مترجم"
                                                                          id="prAttributes1"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>ناشر</Form.Label>
                                                            <Form.Control type="text" placeholder="ناشر"
                                                                          id="prAttributes2"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>سال انتشار</Form.Label>
                                                            <Form.Control type="text" placeholder="سال انتشار"
                                                                          id="prAttributes3"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>نوبت چاپ</Form.Label>
                                                            <Form.Control type="text" placeholder="نوبت چاپ"
                                                                          id="prAttributes4"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>موضوع</Form.Label>
                                                            <Form.Control type="text" placeholder="موضوع"
                                                                          id="prAttributes5"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>تعداد صفحات</Form.Label>
                                                            <Form.Control type="text" placeholder="تعداد صفحات"
                                                                          id="prAttributes6"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>قطع</Form.Label>
                                                            <Form.Control type="text" placeholder="قطع"
                                                                          id="prAttributes7"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>ابعاد</Form.Label>
                                                            <Form.Control type="text" placeholder="ابعاد"
                                                                          id="prAttributes8"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>جلد</Form.Label>
                                                            <Form.Control type="text" placeholder="جلد"
                                                                          id="prAttributes9"/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>لینک تصویر</Form.Label>
                                                            <Form.Control type="text"
                                                                          placeholder="پس از آپلود عکس لینک عکس نمایان می شود!"
                                                                          id="productImageUrl" readOnly/>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Form.Group className="mb-3" htmlFor="formBasicCheckbox">
                                                    <Form.Check type="checkbox" label="مستقیم روی سایت قرار گیرد؟
                                                    " id="prStatus"/>
                                                </Form.Group>
                                            </ListGroup.Item>

                                            <Button variant="primary" type="submit" className="mt-3">
                                                ثبت محصول
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </>

        )
    }
}
